"use client";

import { IPost } from "@/types/wordpress-types";
import Link from "next/link";
import { BannerImage } from "@/components/BannerImage";
import React from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

interface BannerAdsProps {
  location: "top" | "bottom";
  type: "category" | "manual";
  cat_banners: false | IPost[];
  chosen_banners: false | IPost[];
  loop: boolean;
  classes?: string;
}

export function BannerAds({
  location,
  type,
  cat_banners,
  chosen_banners,
  loop,
  classes,
}: BannerAdsProps) {
  let banners: false | IPost[];

  if (type === "category") {
    banners = cat_banners;
  } else {
    banners = chosen_banners;
  }

  if (!banners) {
    return <></>;
  }

  const autoplayTimer = 5000;

  let bannerLink;

  if (!loop) {
    banners = banners.slice(0, 1);

    bannerLink = banners.length > 0 ? banners[0]?.acf?.banner_link?.url : false;
  }

  const wrapperClasses = clsx(
    "wrapper",
    location === "bottom" && "max-md:mb-10 md:mb-[60px]",
  );

  if (!banners) {
    return <></>;
  }

  return (
    <div className={`wrapper mt-10 ${wrapperClasses} ${classes}`}>
      {!loop &&
        banners.length > 0 &&
        (bannerLink ? (
          <Link
            aria-label={`${banners[0]?.post_title} Banner`}
            href={banners[0]?.acf?.banner_link?.url}
            target={banners[0]?.acf.banner_link?.target}
            rel={
              banners[0]?.acf?.banner_link?.target === "_blank"
                ? "noopener noreferrer"
                : ""
            }
          >
            <BannerImage banner={banners[0]} location={location} />
          </Link>
        ) : (
          <BannerImage banner={banners[0]} location={location} />
        ))}

      {loop && banners.length > 0 && (
        <Swiper
          spaceBetween={10}
          modules={[Autoplay]}
          speed={400}
          grabCursor={true}
          draggable={true}
          autoplay={{
            delay: autoplayTimer,
            disableOnInteraction: false,
          }}
        >
          {banners.map((banner, index) =>
            banner.acf.banner_link ? (
              <SwiperSlide key={banner.ID}>
                <Link
                  aria-label={`${banner.post_title} Banner`}
                  href={banner.acf.banner_link?.url}
                  target={banner.acf.banner_link.target}
                  rel={
                    banner.acf.banner_link.target === "_blank"
                      ? "noopener noreferrer"
                      : ""
                  }
                >
                  <BannerImage banner={banner} location={location} />
                </Link>
              </SwiperSlide>
            ) : (
              <Swiper className="panel mr-5" key={banner.ID}>
                <BannerImage banner={banner} location={location} />
              </Swiper>
            ),
          )}
        </Swiper>
      )}
    </div>
  );
}
