import Image from "next/image";
import Link from "next/link";
import React from "react";
import { ILinkField } from "@/types/wordpress-types";

type Props = {
  view_more_link: ILinkField;
  classes?: string;
};
export function ViewMoreLink({ view_more_link, classes }: Props) {
  return (
    <Link
      className={`group view-more-link z-[8] flex gap-x-1 ${classes}`}
      href={view_more_link.url}
    >
      {view_more_link.title}
      <Image
        className="inline-block ease-out transition-all duration-300 right-0 max-md:hidden
        relative group-hover:transition group-hover:duration-200 group-hover:ease-linear
        group-hover:right-[-4px]"
        src="/icons/arrow_right.svg"
        alt=""
        width={12}
        height={9}
      />
    </Link>
  );
}
