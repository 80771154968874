/**
 * This file is a helper file for putting together preset card layouts
 * for use across the site and different ACF layouts
 *
 * PN uses a lot of different styles of cards scattered across the site.
 *
 * Add to the collection below if needed...
 *
 */

import dynamic from "next/dynamic";
import Image from "next/image";
import parse from "html-react-parser";

import { IPost } from "@/types/wordpress-types";

import {
  BodyContent,
  CardContainer,
  Date,
  Excerpt,
  MediaContent,
  MetaContent,
  MetaData,
  PostImage,
  Title,
} from "@/components/cards/CardElements";
import Styles from "@/styles/Cards.module.scss";
import { ICounterCard } from "@/types/ACFLayouts/CounterCards";

// Lazy Load Imports
const Counters = dynamic(
  () => import("@/components/ui/Counters").then((mod) => mod.Counters),
  { ssr: true }
);
const ButtonGroup = dynamic(
  () =>
    import("@/components/buttons/ButtonGroup").then((mod) => mod.ButtonGroup),
  { ssr: true }
);

type Props = {
  post: IPost;
  ratio?: string;
  classes?: string;
  metaField?: string;
  showDate?: boolean;
};

// Title, Keyword Meta + Date
export function TitleKeywordDateCard({
  classes,
  post,
  metaField,
  showDate = true,
}: Props) {
  return (
    <CardContainer classes={`${classes} ${Styles["v-card"]}`}>
      <BodyContent>
        <Title post={post} />

        {/*  Meta (Keyword)  */}
        {((metaField && (post[metaField] || post?.acf[metaField])) ||
          post?.formatted_date) && (
          <MetaContent classes={post.post_type}>
            <MetaData post={post} metaField={metaField} />
            {showDate && <Date post={post} />}
          </MetaContent>
        )}
      </BodyContent>
    </CardContainer>
  );
}

// Image, Title, Keyword Meta + Date
export function ImageTitleKeywordDateCard({
  classes,
  post,
  ratio,
  metaField,
}: Props) {
  return (
    <CardContainer classes={`${classes} ${Styles["v-card"]}`}>
      {/* Card Media */}
      <MediaContent>
        <PostImage
          post={post}
          ratio={ratio}
          classes={`top-0 left-0 absolute object-cover object-center`}
        />
      </MediaContent>

      {/* Card Body */}
      <BodyContent>
        <Title post={post} />

        {/*  Meta (Keyword)  */}
        {((metaField && (post[metaField] || post?.acf[metaField])) ||
          post?.formatted_date) && (
          <MetaContent classes={post.post_type}>
            <MetaData post={post} metaField={metaField} />
            <Date post={post} />
          </MetaContent>
        )}
      </BodyContent>
    </CardContainer>
  );
}

// Image, Title, Keyword Meta
export function ImageTitleKeywordCard({
  classes,
  post,
  ratio,
  metaField,
}: Props) {
  return (
    <CardContainer classes={`${classes} ${Styles["v-card"]}`}>
      {/* Card Media */}
      <MediaContent
        classes={
          "max-sm:shrink max-sm:basis-[130px] max-sm:!h-[130px] overflow-hidden same-height3"
        }
      >
        <PostImage
          post={post}
          ratio={ratio}
          classes={"max-sm:w-[130px] h-full max-sm:!h-[130px] sm:wide-ratio"}
        />
      </MediaContent>

      {/* Card Body */}
      <BodyContent>
        <Title post={post} classes={`same-height post-type-${post.type}`} />

        {/*  Meta (Keyword)  */}
        {((metaField && (post[metaField] || post?.acf[metaField])) ||
          post?.formatted_date) && (
          <MetaContent classes={post.post_type || post.type}>
            <MetaData classes={"keyword"} post={post} metaField={metaField} />
          </MetaContent>
        )}
      </BodyContent>
    </CardContainer>
  );
}

// Image, Title, Excerpt, Keyword Meta
export function ImageTitleExcerptKeywordCard({
  classes,
  post,
  ratio,
  metaField,
}: Props) {
  return (
    <CardContainer classes={`${classes} ${Styles["v-card"]}`}>
      {/* Card Media */}
      <MediaContent>
        <PostImage
          post={post}
          ratio={ratio}
          classes={`top-0 left-0 absolute object-cover object-center`}
        />
      </MediaContent>

      {/* Card Body */}
      <BodyContent>
        <Title classes={"h1"} post={post} />

        <Excerpt post={post} />

        {((metaField && (post[metaField] || post?.acf[metaField])) ||
          post?.formatted_date) && (
          <MetaContent classes={post.post_type}>
            <MetaData post={post} metaField={metaField} />
          </MetaContent>
        )}
      </BodyContent>
    </CardContainer>
  );
}

interface CounterCardProps {
  card: ICounterCard;
  cardClasses: string;
  orientation: string;
  content_width: string;
}

export function CounterCard({
  card,
  cardClasses,
  orientation,
  content_width,
}: CounterCardProps) {
  return (
    <CardContainer classes={`${cardClasses} bg-pn-off-white-base`}>
      {/*  Card Media - Image  */}
      <MediaContent classes={"!h-[unset]"}>
        <Image
          src={`${process.env.NEXT_PUBLIC_API_URL}${card?.image.url}`}
          alt={card?.image.alt}
          width={card?.image.width}
          height={card?.image.height}
          loading={"lazy"}
          sizes="(max-width: 800px) 100vw, 60vw"
          className="h-full object-cover object-center"
        />
      </MediaContent>

      {/*  Card Body Content  */}
      <BodyContent
        classes={"gap-5 !justify-start max-md:!p-5 md:!p-10"}
        orientation={orientation}
        content_width={content_width}
      >
        <h1 className="font-light">{card.title}</h1>

        <Counters counters={card.counters} />

        <div className="content content-flow">
          {parse(card.card_text)}

          {card?.links && <ButtonGroup links={card.links} />}
        </div>
      </BodyContent>
    </CardContainer>
  );
}
