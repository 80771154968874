"use client";

// External Imports
import React, { createContext } from "react";

// Type Imports
import { IPage, IPost } from "@/types/wordpress-types";
import type { IQueryPosts } from "@/types/ACFLayouts/QueryPosts";
import type { IDynamicPosts } from "@/types/ACFLayouts/DynamicPosts";
import { IFeaturedNews } from "@/types/ACFLayouts/FeaturedNews";

interface CardContextType {
  post: undefined | IPost;
  data: undefined | IQueryPosts | IDynamicPosts;
}

export const CardContext = createContext<CardContextType>({
  post: undefined,
  data: undefined,
});

type Props = {
  data: IQueryPosts | IDynamicPosts;
  children: React.ReactNode;
  post: IPost;
};

export const CardContextProvider = ({ data, post, children }: Props) => {
  return (
    <CardContext.Provider value={{ post, data }}>
      {children}
    </CardContext.Provider>
  );
};
