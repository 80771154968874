import React from "react";

type GridProps = {
  classes?: string;
  columns?: number;
  children: React.ReactNode;
};

type ColumnProps = {
  columns?: number;
  classes?: string;
  children: React.ReactNode;
};

export function Grid({ columns, classes, children }: GridProps) {
  // Predefined columns available if wanted,
  // Else just simply add your own classes to the Grid component...
  const gridClasses: { [key: number]: string } = {
    1: `grid-cols-1`,
    2: `grid-cols-1 sm:grid-cols-2`,
    3: `grid-cols-1 sm:grid-cols-2 md:grid-cols-3`,
    4: `grid-cols-1 sm:grid-cols-2 md:grid-cols-4`,
    5: `grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5`,
    6: `grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6`,
  };

  return (
    <section
      className={`grid gap-5 md:gap-10 ${columns ? gridClasses[columns] : ""} ${classes}`}
    >
      {children}
    </section>
  );
}

export function Column({ children, classes }: ColumnProps) {
  return (
    <div
      className={`relative flex flex-col col-span-1 ${classes ? classes : ""}`}
    >
      {children}
    </div>
  );
}
