import React from "react";
import {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from "html-react-parser";

export const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    // Check if the domNode is of type Element
    if (domNode.type === "tag" && (domNode as Element).name === "p") {
      // Replace <p> tag with its children
      return <>{domToReact(domNode.children as DOMNode[])}</>;
    }
  },
};
