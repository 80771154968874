import parse from "html-react-parser";
import React from "react";

type Props = {
  intro: string;
  children?: React.ReactNode;
  classes?: string;
};

export const LayoutIntro = React.forwardRef(function LayoutIntro(
  { intro, children, classes }: Props,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <>
      <section
        ref={ref}
        className={`mb-10 flex items-center justify-between gap-x-[4%] ${classes}`}
      >
        <div className="content content-flow paragraph-formatting--light intro">
          {parse(intro)}
        </div>
        {children}
      </section>
    </>
  );
});
