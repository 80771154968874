/**
 * This File is the entrypoint for the custom card builder using ACF Flexible Content in WordPress
 *
 * This Component is used within the AdvancedQueryPosts ACF Layout for building custom card layouts.
 *
 * Cards can consist of Media, Body Content + Metadata which are each separated out
 * into their own files to make extending, updating, changing these easier.
 *
 */

// External Imports
import dynamic from "next/dynamic";

// Internal Imports
import { CardContextProvider } from "@/components/providers/CardContextProvider";

// Type Imports
import { IPost } from "@/types/wordpress-types";
import type { IQueryPosts } from "@/types/ACFLayouts/QueryPosts";
import type { IDynamicPosts } from "@/types/ACFLayouts/DynamicPosts";

// Lazy Load Imports
const DefaultDynamicCard = dynamic(() =>
  import("@/components/cards/DefaultDynamicCard").then(
    (mod) => mod.DefaultDynamicCard,
  ),
);
const CardMediaLayout = dynamic(() =>
  import("@/components/acfFlexibleContentCardLayouts/ACFCardMedia").then(
    (mod) => mod.CardMediaLayout,
  ),
);
const CardBodyLayout = dynamic(() =>
  import("@/components/acfFlexibleContentCardLayouts/ACFCardBody").then(
    (mod) => mod.CardBodyLayout,
  ),
);
const CardMetaLayout = dynamic(() =>
  import("@/components/acfFlexibleContentCardLayouts/ACFCardMeta").then(
    (mod) => mod.CardMetaLayout,
  ),
);

type Props = {
  data: IQueryPosts | IDynamicPosts;
  post: IPost;
  classes?: string;
};

export const ACFCardBuilder = ({ data, post }: Props) => {
  const { card_builder } = data;

  return (
    <CardContextProvider data={data} post={post}>
      {!!card_builder ? (
        <>
          {card_builder.map((layout, index) => {
            if (layout.acf_fc_layout === "CardMedia") {
              return (
                <CardMediaLayout
                  key={`${layout.acf_fc_layout}-${index}`}
                  layout={layout}
                />
              );
            }
            if (layout.acf_fc_layout === "CardBody") {
              return (
                <CardBodyLayout
                  key={`${layout.acf_fc_layout}-${index}`}
                  layout={layout}
                />
              );
            }
            if (layout.acf_fc_layout === "CardMeta") {
              return (
                <CardMetaLayout
                  key={`${layout.acf_fc_layout}-${index}`}
                  layout={layout}
                />
              );
            }
          })}
        </>
      ) : (
        <DefaultDynamicCard />
      )}
    </CardContextProvider>
  );
};
