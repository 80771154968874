import { IACFLayouts } from "@/types/wordpress-types";

type Props = IACFLayouts;

export function getDefaultACFSettings(data: Props) {
  const hide = data.hide;
  const paddings = Object.values(data.padding).join(" ").trim();
  const margins = Object.values(data.margin).join(" ").trim();
  const classes = data.classes
    ? data.classes.map((c) => c.class).join(" ")
    : "";
  const id = data.id ? data.id : undefined;
  const backgroundColor = data.block_background_colour ?? "";
  const backgroundImage = data.block_background_image
    ? data.block_background_image
    : "";

  let bgImgStyles = {};

  if (backgroundImage) {
    bgImgStyles = {
      background: `no-repeat center center url('${
        process.env.NEXT_PUBLIC_API_URL + backgroundImage
      }')`,
      backgroundSize: "cover",
    };

    if (backgroundColor) {
      bgImgStyles = {
        ...bgImgStyles,
        backgroundBlendMode: "overlay",
      };
    }
  }

  return {
    hide,
    paddings,
    margins,
    classes,
    id,
    backgroundColor,
    bgImgStyles,
  };
}
