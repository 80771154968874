"use client";

/**
 * This file is a central location where card elements
 * can be defined once and used wherever needed in any context.
 *
 * This file primarily concerns itself with defining atoms such as
 * title, date, excerpt, etc.
 *
 * These atoms are used across multiple components as needed to build a card
 *
 * Add to this file as needed...
 */

// External Imports
import parse, {
  domToReact,
  HTMLReactParserOptions,
  Element,
  DOMNode,
} from "html-react-parser";
import React, { useContext } from "react";
import Link from "next/link";
import Image from "next/image";

// Internal Imports
import Styles from "@/styles/Cards.module.scss";

// Type Imports
import { IPost } from "@/types/wordpress-types";
import { CardContext } from "@/components/providers/CardContextProvider";
import CardStyles from "@/styles/Cards.module.scss";

interface Props {
  post: IPost | undefined;
  classes?: string;
  metaField?: string;
  sizes?: string;
  ratio?: string;
  onLoad?: () => void;
}

type BodyProps = {
  children?: React.ReactNode;
  orientation?: string;
  content_width?: string;
  classes?: string;
  post?: IPost;
};

type MetaProps = {
  children?: React.ReactNode;
  classes?: string;
};

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    // Check if the domNode is of type Element
    if (domNode.type === "tag" && (domNode as Element).name === "p") {
      // Replace <p> tag with its children
      return <>{domToReact(domNode.children as DOMNode[])}</>;
    }
  },
};

// Card Container
export function CardContainer({ children, classes }: BodyProps) {
  return (
    <article className={`card group ${Styles.card} ${classes ? classes : ""}`}>
      {children}
    </article>
  );
}

// Vertical Card Container
export function VCard({ post, children, classes }: BodyProps) {
  const taxonomies = post?.taxonomies?.join(" ")?.toLowerCase();

  return (
    <article
      className={`card ${Styles.card} ${Styles["v-card"]} group post single-post ${post?.post_type || post?.type} ${taxonomies} ${classes ? classes : ""}`}
    >
      {children}
    </article>
  );
}

interface HCardsContainerProps {
  children: React.ReactNode;
  columns: string;
  direction: string;
  stacking: string;
  classes?: string;
}

// Horizontal Cards Container
export function HCardsContainer({
  children,
  columns,
  direction,
  stacking,
  classes,
}: HCardsContainerProps) {
  if (columns === "stack") {
    classes += ` ${Styles[stacking]}`;
  }

  return (
    <section
      className={`relative h-cards ${Styles["h-cards"]} ${Styles[columns]} ${Styles[direction]} ${classes ? classes : ""}`}
    >
      {children}
    </section>
  );
}

// Horizontal Card Container
export function HCard({ post, children, classes }: BodyProps) {
  const taxonomies = post?.taxonomies?.join(" ")?.toLowerCase();

  return (
    <article
      className={`card group ${Styles.card} ${Styles["h-card"]} ${post?.post_type || post?.type} ${taxonomies} ${classes ? classes : ""}`}
    >
      {children}
    </article>
  );
}

// Card Body
export function BodyContent({
  children,
  orientation,
  content_width,
  classes,
}: BodyProps) {
  let widthClasses = orientation === "horizontal" ? content_width : "";

  return (
    <div
      className={`card-body same-height2 content ${
        Styles["card-body"]
      } ${widthClasses} ${classes ? classes : ""}`}
    >
      {children}
    </div>
  );
}

// Meta Content
export function MetaContent({ children, classes }: BodyProps) {
  return (
    <div
      className={`${Styles["card-meta"]} ${classes ? classes : ""} post-meta ${Styles["post-meta"]}`}
    >
      {children}
    </div>
  );
}

// Media Content
export function MediaContent({ children, classes }: BodyProps) {
  return (
    <div className={`card-media overflow-hidden swiper-zoom-container ${Styles.media} ${classes}`}>
      {children}
    </div>
  );
}

// Excerpt
export function Excerpt({ post, classes }: Props) {
  if (!post?.post_excerpt) return <></>;

  return (
    <div
      className={`${classes} content content-flow post-excerpt ${Styles["post-excerpt"]}`}
    >
      <p>{parse(post?.post_excerpt, options)}</p>
    </div>
  );
}

// Title
export function Title({ post, classes }: Props) {
  const postTitle = post?.post_title
    ? post?.post_title
    : post?.title?.rendered
      ? post?.title?.rendered
      : false;

  if (!postTitle) return <></>;

  return (
    <h3
      className={`h2 card-title leading-tight post-type-${post?.post_type || post?.type} ${classes}`}
    >
      <Link
        className={`block group-hover:underline`}
        href={post?.link || post?.slug}
      >
        {parse(postTitle as string)}
      </Link>
    </h3>
  );
}

// Read More Link...
export function ReadMore({ post, classes }: Props) {
  if (!post?.slug) return <></>;

  return (
    <Link
      className={`btn btn-md btn-primary`}
      href={`${post?.link || post?.slug}`}
    >
      Read more
    </Link>
  );
}

// Dynamic Meta Data Field
export function MetaData({ post, classes, metaField }: Props) {
  if (!post) return <></>;

  let getMeta = false;

  if (metaField) {
    getMeta = post[metaField]
      ? post[metaField]
      : post?.acf[metaField]
        ? post.acf[metaField]
        : false;
  }

  if (metaField && !getMeta) {
    return <></>;
  }

  if (metaField === 'excerpt') {
    return (
      <div className="content content-flow">
        {parse(String(getMeta))}
      </div>
    )
  }

  return (
    <p
      className={`text-sm leading-tight font-normal mt-auto post-meta post-meta-${getMeta} ${classes} ${metaField} ${
        post?.post_type || post?.type
      } post-type-${post?.post_type || post?.type}`}
    >
      {metaField && parse(String(getMeta))}
    </p>
  );
}

// Date
export function Date({ post, classes }: Props) {
  if (!post?.formatted_date) return <></>;

  return <small className={`meta date`}>{post?.formatted_date}</small>;
}

// Author
export function Author({ post, classes }: Props) {
  if (!post?.author && !post?.author_name) return <></>;

  return (
    <small className={`meta author`}>{post?.author || post?.author_name}</small>
  );
}

// Category
export function Category({ post, classes }: Props) {
  if (!post?.categories) return <></>;

  return (
    <>
      {post.categories.map((cat) => (
        <small key={cat} className={`meta category category-${cat}`}>
          {cat}
        </small>
      ))}
    </>
  );
}

// Taxonomy
export function Taxonomy({ post, classes }: Props) {
  if (!post?.taxonomies) return <></>;

  return (
    <>
      {post.taxonomies.map((term) => (
        <small key={term} className={`meta category taxonomy taxonomy-${term}`}>
          {term}
        </small>
      ))}
    </>
  );
}

// Image
export function PostImage({ post, classes, sizes, ratio }: Props) {
  const { data } = useContext(CardContext);

  if (!post?.featured_image) return <></>;

  if (!post) return <></>;

  const durationClass =
    data?.display_in_a_slider && data?.orientation === "horizontal"
      ? "group-hover/image:duration-400"
      : "group-hover/image:duration-200";

  return (
    <Link
      aria-label={post?.post_title || post?.title?.rendered}
      href={`${post?.link || post?.slug}`}
      className={`relative media-link group/image overflow-hidden ${classes} ${Styles["media-link"]} ${post.ratio} 
      ${ratio} ${Styles[ratio as string]} ${Styles[`${ratio}-ratio`]}`}
    >
      <Image
        src={`${process.env.NEXT_PUBLIC_API_URL}${post?.featured_image}`}
        alt={""}
        fill
        sizes={sizes}
        className={`transition-all relative duration-400 ease-out origin-center object-cover object-center
         group-hover/image:image-scale ${durationClass} group-hover/image:ease-linear
          group-hover/image:origin-center`}
      />
    </Link>
  );
}
